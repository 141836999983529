import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 20 cm de diàmetre, de color clar blanquinós amb tons lleugerament verdosos o grisencs, convex, bastant carnós amb el marge enrotllat llarg temps i ondulat quan és adult. Davall el capell trobem tubs no gaire llargs de color verdós que torna blavós quan exercim pressió amb els dits, els porus, rodons o angulosos, verdosos o de color groc viu que també canvien el color amb la pressió dels dits. El peu és un poc curt, bulbós, de gruixut a més fi, presentant una coloració groc pàl·lida a la part superior. Les espores són terroses, olivàcies en massa, fusiformes, de 12-18 x 4-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      